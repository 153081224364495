import React, { useState, useEffect  } from 'react';
import { RiMenu3Line, RiClose, RiCloseLine } from  'react-icons/ri'

import logo from '../../assets/logo.svg';
import './navbar.css';

// BEM  - > Block - Element -
const Menu = () => (
  <>
    <ul>
      <li><a href="#home">Home</a></li>
      <li><a href="#wjhm">What is GPT?</a></li>
      <li><a href="#features">Case Studies</a></li>
      <li><a href="#possibility">Open AI</a></li>
      <li><a href="#blog">Library</a></li>
    </ul>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navBarFix, setnavBarFix] = useState(false);
  
  useEffect(() => {
    const handScroll = () => {
      setnavBarFix(window.scrollY >= 120)
    };
    window.addEventListener('scroll', handScroll)
  }, []);
  return (
    <div className={ `navbar space-wrap font-base    ${navBarFix? 'navbar-fix' : ''} `}  >
      <div className="navbar__container container">
        <div className="navbar__left">
          <div className="navbar__logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="navbar__menu">
            <Menu />
          </div>
        </div>
        <div className="navbar__right">
          <div className="user"><a>Sign in</a>
          <a className="btn_active spaceElement-left">Sign up</a></div>
          <div className="navbar__menu-responsive">
            <div className="navbar__responsiveIcon spaceElement-left">
              {
                toggleMenu ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                  : <RiMenu3Line color="#fff" size={27} onClick={ () => setToggleMenu(true)}/>
              }
            </div>
            {toggleMenu && (
              <div className="menu-responsive scale-up-center">
                <div className="flex">
                <Menu />
                <div className="user">
                  <a>Sign in</a>
                  <a className="btn_active spaceElement-left">Sign up</a>
                </div>
                </div>
              </div>)
            }
          </div>
          
        </div>
        
      </div>
    </div>
  )
}

export default Navbar