import React from 'react';

import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
const Header = () => {
  return (
    <div id="home" className="header space-wrap">
      <div className="header_container  container">
        <div className="header__left">
          <h1 className="gradient__text">Let’s Build Something
            amazing with GPT-3  <br className="hide_m" />
            OpenAI</h1>
          <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
          <div className="header__formcontact">
            <input type="text" name="" placeholder="You Email Address" />
            <button type="button">Get Start</button>
          </div>
          <div className="header__people">
            <img src={people} alt="people" /> 
            <span>1,600 people requested access a visit in last 24 hours </span>
          </div>
        </div>
        <div className="header__right">
        <img src={ai} alt="ai" /> 
        </div>
        
      </div>
    </div>
  )
}

export default Header