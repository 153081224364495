import React from 'react'

import { Header, Footer, Blog, Features, WhatJHM, Possibility } from './containers';
import { Brand, CTA, Navbar } from './components';
import './App.css';
const App = () => {
  return (
    <div className="app">
      <div className="header-gradient__top">
        <Navbar />
        <Header />
        <Brand />
      </div>
      <div className="content-gradient__top"> 
        <WhatJHM />
        <Features />
        <Possibility />
        <CTA />
      </div>
      <Blog />
      <Footer />
    </div>
  )
}

export default App