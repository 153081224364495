import React from 'react';


import './post.css';
const Post = ({post}) => {
  return (
    <div className="post">
      <img src={process.env.PUBLIC_URL + `${post.img}`} alt="logo" />
      <div className="post__content">
        <article>
          <p><small> {post.timestamp}</small></p>
          <h3> {post.title} </h3>
        </article>
        <p><small><a href="#">Read Full Article</a></small></p>
      </div>
    </div>
  )
}

export default Post