import React from 'react';

import './feature.css';
const Feature = ( {title, text}) => {
  return (
    <>
      <h3 className="title__feature">{ title }</h3>
      <p className="text"> { text }</p>
    </>
  )
}

export default Feature