import React from 'react';

import  Feature from '../../components/feature/Feature'
import './whatjhm.css';
const WhatJHM = () => {
  return (
    <div id="wjhm" className="whatjhm space-wrap">
      <div className="whatjhm__container container">
        <section className="whatjhm__intro gird__twocolumns-p2">
          <Feature title="What is GPT-3" text="We so opinion friends me message as delight. Whole front do of plate heard oh ought. His defective nor convinced residence own. Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by." />
        </section>
        <section className="whatjhm__title" >
          <h2 className="gradient__text title__heading-normal">The possibilities are beyond your imagination</h2>
          <p className='explore-library color-orange'><a href="#" target="_blank">Explore The Library</a></p>
        </section>
        <section className="whatjhm_body gird__threecolumns">
          <div className='whatjhm__column'>
            <Feature title="Chatbots" text="We so opinion friends me message as delight. Whole front do of plate heard oh ought." />
          </div>
          <div className='whatjhm__column'>
            <Feature title="Knowledgebase" text="At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. As put impossible own apartments b " />
          </div>
          <div className='whatjhm__column'>
            <Feature title="Education" text="At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by. As put impossible own apartments b" />
          </div>
        </section>
      </div>  
    </div>
  )
}

export default WhatJHM