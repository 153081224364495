import React from 'react';

import logo from '../../assets/logo.svg';
import './footer.css';
const Footer = () => {
  return (
    <footer className="footer space-wrap">
      <div className="footer__container container">
        <div className="footer__heading">
          <h2 className="gradient__text title__heading-big">Do you want to step in to the  <br className="hide_m"/>  future before others</h2>
          <p> <a href="#" className="btn">Request Early Access</a> </p>
        </div>
        <div className="footer__content">  
          <div className="footer__menu">
            <section>
              <h3>Links</h3>
              <ul>
                <li><a href="#">Overons</a></li>
                <li><a href="#">Social Media</a></li>
                <li><a href="#">Counters</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </section>
            <section>
              <h3>Company</h3>
              <ul>
                <li><a href="#">Terms &amp; Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </section>
            <section>
              <h3>Get in touch</h3>
              <ul>
                <li><a href="#">Crechterwoord K12 <br className="hide_m" /> 182 DK Alknjkcb</a></li>
                <li><a href="#">085-132567</a></li>
                <li><a href="#">info@payme.net</a></li>
              </ul>
            </section>
          </div> 
          <section className="footer__logo">
            <img src={logo} alt="logo" />
            <p>Crechterwoord K12 182 DK <br /> Alknjkcb, All Rights Reserved</p>
          </section>
        </div>
        <div className='allrs'>© 2021 GPT-3. All rights reserved.</div>
      </div>
    </footer>
  )
}

export default Footer