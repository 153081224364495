import React from 'react';

import './cta.css';
const CTA = () => {
  return (
    <div className="cta space-wrap">
      <div className="cta__container container">
        <section>
          <small>Request Early Access to Get Started</small>
          <h3>Register today &amp; start exploring the endless possiblities.</h3>
        </section>
        <section>
          <a href="#" className='btn'>Get Started</a>
        </section>
      </div>
    </div>
  )
}

export default CTA