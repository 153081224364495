import React from 'react';

import  Feature from '../../components/feature/Feature'
import './features.css';

const FeatureData = [
  {
    title: 'Improving end distrusts instantly',
    text:  'From they fine john he give of rich he. They age and draw mrs like. Improving end distrusts may instantly was household applauded.'
  },
  {
    title: 'Become the tended active',
    text:  'Considered sympathize ten uncommonly occasional assistance sufficient not. Letter of on become he tended active enable to.'
  },
  {
    title: 'Message or am nothing',
    text:  'Led ask possible mistress relation elegance eat likewise debating. By message or am nothing amongst chiefly address.'
  },
  {
    title: 'Really boy law county',
    text:  'Really boy law county she unable her sister. Feet you off its like like six. Among sex are leave law built now. In built table in an rapid blush.'
  }
]

const Features = () => {
  return (
    <div id="features" className="features space-wrap">
      <div className="features__container container">
        <div className="features__container-left">
          <h2 className="gradient__text title__heading-normal">The Future is Now and <br className="hide_m" />  You Just Need To Realize <br className="hide_m" /> It. Step into Future Today<br className="hide_m" /> &amp; Make it Happen.</h2>
          <p className="color-orange">Request Early Access to Get Started</p>
        </div>
        <div className="features__container-right">
          {FeatureData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Features