import React from 'react';

import possibility from '../../assets/possibility.png';
import './possibility.css';
const Possibility = () => {
  return (
    <div id="possibility" className="possibility space-wrap">
      <div className="possibility__container container">
        <div className="possibility__image">
        <img src={possibility} alt="possibility" />
        </div>
        <div className="possibility__content">
          <h4>Request Early Access to Get Started</h4>
          <h2 className="gradient__text title__heading-normal">The possibilities are beyond your imagination</h2>
          <p className="text">Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
          <p className="color-orange">Request Early Access to Get Started</p>
        </div>
      </div>
      
    </div>
  )
}

export default Possibility