import React from 'react';

import Post from '../../components/post/Post';
import './blog.css';
const Posts = [
  {
    title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
    timestamp: 'Sep 26, 2021',
    img: 'blog01.png'
  },
  {
    title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
    timestamp: 'Sep 26, 2021',
    img: 'blog02.png'
  },    {
    title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
    timestamp: 'Sep 26, 2021',
    img: 'blog03.png'
  },    {
    title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
    timestamp: 'Sep 26, 2021',
    img: 'blog04.png'
  },    {
    title: 'GPT-3 and Open  AI is the future. Let us exlore how it is?',
    timestamp: 'Sep 26, 2021',
    img: 'blog05.png'
  },
]

const Blog = () => {

  return (
    <div id="blog" className="blog space-wrap">
      <div className="blog__container container">
        <div className="blog__heading">
          <h2 className="gradient__text title__heading-big">A lot is happening,  <br className="hide_m" />
We are blogging about it.</h2>
        </div>
        <div className="blog__content">
          {Posts.map((item, index) => (
            <Post post= {item}  key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Blog